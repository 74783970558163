//ikkim FCM
import store from "@/store";

const FCM_SERVER_KEY = 'AAAAuY2vDsQ:APA91bGd-3c7vGaGYQ4BvmwYRnlTcay5Ko_oQuHaSG50fz1DrALiXoxZgGw3lPh2D-P0eC0Naj6VHnLamMI_7faF3ZF6p3Mz2tyZJ545V7kktitNL71w1uSQi9J-lnjiR5QZdO5HPZTG';
//const useMesageService = (!navigator.userAgent.toLowerCase().includes("iphone") && !navigator.userAgent.includes("IMG1000A"))
const IS_APP_DEV = process.env.VUE_APP_DEV == 'true'

//220121 : gateway portal web, iphone일 경우 meessage service를 지원하지 않는다. (Gateway Webview, iPhone에서는 firebase를 지원하지 않아 앱이 실행안됨)

function isAllowFCM () {
  //alert(navigator.userAgent);
  //if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|IMG1000A/i.test(navigator.userAgent)) {
  //안드로이드 제품에서는 허용하기로 한다.
  if(/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|IMG1000A/i.test(navigator.userAgent)) {  
      return false;
  } else {
      return true;
  }
}

/*
function getAccessToken() {
  return new Promise(function(resolve, reject) {
    const key = require('./misewatchweb-firebase-key.json');
    const jwtClient = new google.auth.JWT(
      key.client_email,
      null,
      key.private_key,
      SCOPES,
      null
    );
    jwtClient.authorize(function(err, tokens) {
      if (err) {
        reject(err);
        return;
      }
      resolve(tokens.access_token);
    });
  });
}
*/

let barere = "ya29.c.c0ASRK0GbAN-L58d7h4EMn7tW3ruIecM6eOgk2scjbzfB4_7Jk7SE9sgHmd5XytJAkgSv28vJKrnsB7ooWvvzMXhjCKgi8Sdydv080GZlqNAjPXz2723FDoo-Oxi7I2sdjftc4Qcs5Uqf6q_Z2WRqQQSghclxlvMM63Z5wpcont5EUv0qKuoMZMULk9b7-d0ruOw3QlM_4cD8-mmur64uGcsqA7wy_JGSTRE_K6-mYn4V5VCUNgHSjlrutwNdy0KU6mp4BSdqJfeod1uL7tVuQG9Ufz_mk1AHQPMzVyfcBT6Js8Gcu2beYloChjCLZ4YM7gmgL4a68l8hOMTCc2xXMSd5zGWDpEAZtXd796r98w__s5sv6Uwf5uq7pNQT387KWFrlt99QRz4_qvbXsdzR31O747RJXMfeRaclx6VpoSMqhV9-g2IOUrq35_3RlW95qe-_F_szi7au736ZBk0XpgvlaYuvdJ32fsRQ7hRZgsrsIVeasvtQ2nt6WjWYjapcOkm4xM4Z2piept9iOxY-tkFgpSfih5Mqr6iIu0qVmM8r6zybbkk2qfMmU3w6jmXs1vZi7Sczk5-zq76poipwdc4shJxZyX1l9hi28x8xltp1hU41a68Z82j56dh6Jb-U-1Ja4f-OVy6inkjkvjrZuQq8qzeheawu26mO781Ooxj7b-Ut3fJk86Xqg-6jXne3_SVqm6B54FSVmtxyf7M4Run8VbWwl5zVd69bvbc7WlWeFXxhljReihRf3Odyyr_cSxS-5c3qeO4UrQs4avmRru6qZxonmYuyyB_qcIgngrS_hMxhOswUZsMljxYzrcY5_-gpWwUYeg1wQnxBzYBaU_9ydB7sd6iQpncv5u_mnSMQm7bmXr69I_7bmvc58wzZWJSmIiJ_ovglcY9UWmg9oaJSJrcwkaazB1_Mj2qcU1_Xo6Obb-vsv5y05Qbfq-xYfd1mk7Zm5i9mz_67Bhbimyvlrgh28q0Sg6yeu-cebdgZb0n8Uf2iFzQJ";
async function subscriptionInfo(token) {
  token = 'ePum2fDgbNERmGpw6TX1E6:APA91bHNEa5878oaSz6ivv7k6m_FyGHb4FnIapHE4h2W8CJnvHEE3alsMLAoSwFNPOuWpQ5-CatFVE0Rqff4j_pwNb5mHbgzDAZgXZYu5PQ7yF2bdhYEsrE4E16wUnmy3RvkjSCaA5kt';
  //let url = `https://iid.googleapis.com/iid/info/${token}?details=true`;
  let url = `https%3A%2F%2Fiid.googleapis.com%2Fiid%2Finfo%2F${token}?details=true`;
  
  let encoded = encodeURIComponent(url);
//console.log("encoded==="+encoded);
  //fetch(`/api?url=https://iid.googleapis.com/iid/info/${token}?details=true`, {
  fetch(`/api?url=${url}`, {
    method: 'GET',
    //mode: "cors", // no-cors, *cors, same-origin
    //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: "same-origin", // include, *same-origin, omit
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept": "application/json",
      //"Content-Type": "multipart/form-data",
      //"Content-Length": body.length,
      //Authorization: `key=${FCM_SERVER_KEY}`,
      "Authorization": "Bearer " + barere,
      "access_token_auth": "true",
      //"Access-Control-Allow-Origin" : "https://localhost:9080",
    }),
  }).then((response) => {
    if(response.status < 200 || response.status >= 400) {
      console.log(response.status, response);
    } else {
      console.log(`list--->`);
    }
  }).catch((error) => {
    //console.error(error.result);
  });
}

async function subscribeA(token, topic) {
  let body = {
    "to":`/topics/${topic}`,
    "registration_tokens":[token]
  };

  //console.log("aaa="+JSON.stringify(body));
  subscriptionInfo(token);
  if(barere != null) {
    return;
  }

  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
  //fetch("https://iid.googleapis.com/iid/v1:batchAdd", {
    method: 'POST',
    //mode: "no-cors", // no-cors, *cors, same-origin
    //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: "same-origin", // include, *same-origin, omit
    headers: new Headers({
      //"Content-Type": "application/json",
      //"Content-Length": body.length,
      //Authorization: `key=${FCM_SERVER_KEY}`,
      "Authorization": 'Bearer ' + barere,
      "access_token_auth": true
    }),
    //body: body
  }).then((response) => {
    if(response.status < 200 || response.status >= 400) {
      //console.log(response.status, response);
      if(response.status == 401) {
        //console.log("try again A");
        //subscribeA(token, topic);
      }
    } else {
      console.log(`${topic} is subscribed.`);
    }
  }).catch((error) => {
    //console.error(error.result);
  });
}

async function subscribeB(token, topic) {
  let body = JSON.stringify({
    "to":`/topics/${topic}}`,
    "registration_tokens":[topic]
  });

  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: 'POST',
    //mode: "no-cors",
    headers: new Headers({
      //Authorization: `key=${FCM_SERVER_KEY}`,
      //"access_token_auth": "false"
      Authorization: 'Bearer ' + barere,
      "access_token_auth": "true"
    }),
  }).then((response) => {
    if(response.status < 200 || response.status >= 400) {
      //console.log(response.status, response);
      if(response.status == 401) {
        //console.log("try again B");
        //subscribeB(token, topic);
      }
    } else {
      //console.log(`${topic} is subscribed.`);
    }
  }).catch((error) => {
    //console.error(error.result);
  });
}

async function subscribeTokenToTopic(token, topic) {

  if(isAllowFCM() /*useMesageService == true*/ && IS_APP_DEV == false) {  
    //console.log("subscribeTokenToTopic");

    token = store.getters['others/token'];
    if(token == null || topic == null) return;

    let notiReject = store.getters['others/notiReject'];
    if(notiReject == true) {
      console.log('subscribeTokenToTopic: Noti Rejected...');
      return;
    }

    console.log("subscribeTokenToTopic token="+token+" topic="+topic);
    await subscribeA(token, topic);

    //게이트웨이에서 오는 알림은 관리자만 받기로 한다.
    let authInfo = store.getters['auth/authenticationUserInfo'];
    //console.log("authInfo="+JSON.stringify(authInfo));
    if(authInfo.authority == 0 || authInfo.authority == 10) {
      let topicGateway = topic + "-gateway";

      await subscribeB(token, topicGateway);
    }
  }
}

function unSubscribeTokenToTopic(token, topic) {

  if(isAllowFCM() /*useMesageService == true*/) {
  //console.log("unSubscribeTokenToTopic");

    if(token == null || topic == null) return;
    
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `key=${FCM_SERVER_KEY}`,
      }),
    }).then((response) => {
      if(response.status < 200 || response.status >= 400) {
        //console.log(response.status, response);
      }
      //console.log(`${topic} is unsubscribed.`);
    }).catch((error) => {
      //console.error(error.result);
    });

    //게이트웨이에서 오는 알림은 관리자만 받기로 한다.
    let authInfo = store.getters['auth/authenticationUserInfo'];
    if(authInfo.authority == 0 || authInfo.authority == 10) {
      let topicGateway = topic + "-gateway";
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topicGateway}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `key=${FCM_SERVER_KEY}`,
        }),
      }).then((response) => {
        if(response.status < 200 || response.status >= 400) {
          //console.log(response.status, response);
        }
        //console.log(`${topicGateway} is unsubscribed.`);
      }).catch((error) => {
        //console.error(error.result);
      });
    }
  }
}

export default {
    subscribeTokenToTopic,
    unSubscribeTokenToTopic
};