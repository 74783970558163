import Vue from 'vue'

const state = {
  token: null, //ikkim
  notiReject: false,
  showGraph: false, //210928
  platformTitle: null,
  isCMonitorSchool:false, //220211
  isAirSchool:false,
  isShowSearch:false,
  showDetail:2, //221121 -- 자세하게 보기
  showIndicator:false, //221121 -- 전체현황, 실내외 공기질 통합표시 (default:통합공기질 표시안함.)
  showIndividualControl: 1, //221212 -- 전체현황, 개별제어판 표시여부 (default:동작현황으로 표시)
  showInOutDisplayMode: -1, //230608 -- 전체현황에서 실내/실외 표시방식
    //-1: init
    //0: 실내+실외, 실외 표시 안함
    //1: 실내+실외, 실외 표시함
    //2: 실내, 실외 각각 표시 (기존과 동일)
    //3: 리스트 형태로 표시
  leftOverDeviceType: 0, //leftover device type -- 3: 잔반측정기2
  leftGradeWidth: '300px',
  leftGradeWidthMobile: '0px',
  mapLevel:5,
  mapLevelMobile:5,
  lastDashboard: 'main' //마지막 전체현황 타입을 저장한다(main or map)
}

const getters = {
  token: (state) => state.token, //ikkim
  //topic: (state) => state.topic, //ikkim
  notiReject: (state) => state.notiReject, //ikkim
  showGraph: (state) => false/*state.showGraph*/, //ikkim
  getPlatformTitle: (state) => state.platformTitle,
  isCMonitorSchool: (state) => state.isCMonitorSchool, //220211
  isAirSchool: (state) => state.isAirSchool, //220211
  isShowSearch: (state) => state.isShowSearch, //220211
  showDetail: (state) => state.showDetail, //221121
  showIndicator: (state) => state.showIndicator, //221121
  
  //showIndividualControl: (state) => state.showIndividualControl //221212
  showIndividualControl: (state) => 1, //항상 동작현황으로 표시하는것으로 수정 //230311
  //showInOutDisplayMode: (state) => state.showInOutDisplayMode
  showInOutDisplayMode: (state) => {
    //console.log("showInOutDisplayMode==="+state.showInOutDisplayMode);
    if(state.showInOutDisplayMode == -1) {
      let isShelter = Vue.prototype.$common.isShelter();
      //console.log("isShelter==="+isShelter);
      if(isShelter) {
        //shelter인 경우, default: 실내+실외, 실외 표시 안함
        //state.showInOutDisplayMode = 0;
        //241217 실내만 표시로 변경
        state.showInOutDisplayMode = 3;
      } else {
        //shelter인 경우, default: 실내, 실외 각각 표시 (기존과 동일)
        state.showInOutDisplayMode = 2;
      }  
    }
    return state.showInOutDisplayMode;
  },
  leftOverDeviceType: (state) => state.leftOverDeviceType,
  leftGradeWidth: (state) => state.leftGradeWidth,
  leftGradeWidthMobile: (state) => state.leftGradeWidthMobile,
  mapLevel: (state) => state.mapLevel,
  mapLevelMobile: (state) => state.mapLevelMobile,
  lastDashboard : (state) => state.lastDashboard
}

const mutations = {
  setToken(state, tok) {
    state.token = tok;
    //console.log("setToken:"+tok);
  },
  setNotiReject(state, reject) {
    state.notiReject = reject;
    //console.log("setNotiReject:"+reject);
  },
  setShowGraph(state, show) {
    state.showGraph = show;
    //console.log("setShowGraph:"+show);
  },
  //220124
  setPlatformTitle(state, title) {
    state.platformTitle = title;
    //console.log("setNotiReject:"+reject);
  },
  //220211
  setIsCMonitorSchool(state, isCmonitor) {
    state.isCMonitorSchool = isCmonitor;
    //console.log("setIsCMonitorSchool:"+isCmonitor);
  },
  setLeftOverDeviceType(state, type) {
    state.leftOverDeviceType = type;
  },
  setLeftGradeWidth(state, width) {
    state.leftGradeWidth = width;
  },
  setLeftGradeWidthMobile(state, width) {
    state.leftGradeWidthMobile = width;
  },
  setMapLevel(state, width) {
    state.mapLevel = width;
  },
  setMapLevelMobile(state, width) {
    state.mapLevelMobile = width;
  },
  setLastDashboard(state, dashboard) {
    state.lastDashboard = dashboard;
  },
  setIsAirSchool(state, isAir) {
    state.isAirSchool = isAir;
  },
  setIsShowSearch(state, show) {
    state.isShowSearch = show;
  },
  setShowDetail(state, show) {
    state.showDetail = show;
  },
  setShowIndicator(state, show) {
    state.showIndicator = show;
  },
  setShowIndividualControl(state, show) {
    state.showIndividualControl = show;
  },
  setShowInOutDisplayMode(state, mode) {
    //console.log("setShowInOutDisplayMode = "+mode);
    state.showInOutDisplayMode = mode;
  }
}

const actions = {
  setToken({commit}, token) {
    commit('setToken', token);
  },
  setNotiReject({commit}, reject) {
    commit('setNotiReject', reject);
  },
  setShowGraph({commit}, show) {
    commit('setShowGraph', show);
  },
  setPlatformTitle({commit}, title) {
    commit('setPlatformTitle', title);
  },
  setIsCMonitorSchool({commit}, use) {
    commit('setIsCMonitorSchool', use);
  },
  setLeftOverDeviceType({commit}, use) {
    commit('setLeftOverDeviceType', use);
  },
  setLeftGradeWidth({commit}, use) {
    commit('setLeftGradeWidth', use);
  },
  setLeftGradeWidthMobile({commit}, use) {
    commit('setLeftGradeWidthMobile', use);
  },
  setMapLevel({commit}, use) {
    commit('setMapLevel', use);
  },
  setMapLevelMobile({commit}, use) {
    commit('setMapLevelMobile', use);
  },
  setLastDashboard({commit}, use) {
    commit('setLastDashboard', use);
  },
  setIsShowSearch({commit}, show) {
    commit('setIsShowSearch', show);
  },
  setShowDetail({commit}, show) {
    commit('setShowDetail', show);
  },
  setShowIndicator({commit}, show) {
    commit('setShowIndicator', show);
  },
  setShowIndividualControl({commit}, show) {
    commit('setShowIndividualControl', show);
  },
  showInOutDisplayMode({commit}, show) {
    commit('showInOutDisplayMode', show);
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};